import { pojoMetadata } from "../../../auto/js/metadata";
import { PHONE_NUMBER_ORDER_BY_PHONE_NUMBER } from "../../../auto/js/metadata/PhoneNumberOrderBy";
import { rest } from "../../../auto/js/services";

export const phoneNumberFields = [
    {title: "phoneNumber", field: "phoneNumber"}
]

export const buildPhoneNumberData = async (query, id) => {
    let filter = query;
    let data;
    filter["phone-number"] = {civilStatusMtlbId: id};
    filter["orderBy"] = PHONE_NUMBER_ORDER_BY_PHONE_NUMBER;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["phone-number"].columns.forEach(element => {
            if(element.type=='text'){
                filter["phone-number"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getData(filter).then(response => {
        data = response;
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getData = async (filter) => {
    return await rest.search('phone-number', filter)
}

const countData = async (filter) => {
        return await rest.count('phone-number', filter);
}

export const getPhoneNumberEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['phone-number'].form2dto(newData);
            dto["civilStatusMtlbId"] = id;       
            try {
                return rest.create('phone-number', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }        
        })
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                return rest.purge('phone-number', oldData.id).then(() => resolve());
            } catch (err) {
                alert(err);
            }   
        })
    return editables;
}