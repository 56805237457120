import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};
	
const fields = [
		{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
		{name:"image", type:"image", x:1, y:2, layout:"col-md-12"},
		{name:"id", type:"number", x:1, y:3, layout:"col-md-12"},
		{name: "firstname", type: "text", x:1, y:4, layout:"col-md-6"},
		{name: "secondname", type: "text", x:2, y:4, layout:"col-md-6"},
		{name: "thirdname", type: "text", x:1, y: 5, layout:"col-md-6"},
		{name: "fourthname", type: "text", x:2, y: 5, layout:"col-md-6"},
		{name: "gender", type: "select", x:1, y: 6, layout:"col-md-6", metadata: () => gender},
		{name: "maritalStatus", type: "select", x:2, y:6, layout:"col-md-6", metadata: () => maritalStatus},
		{name: "birthdate", type: "date", x:1, y: 7, layout:"col-md-6"},
        {name: "dateOfDeath", type: "date", x:2, y: 7, layout:"col-md-6"},	
		{name: "birthDayUnknown", type: "checkbox", x:1, y:8, layout:"col-md-12"},
		{name: "birthMonthUnknown", type: "checkbox", x:1, y:9, layout:"col-md-12"},
		{name: "birthYearUnknown", type: "checkbox", x:1, y:10, layout:"col-md-12"},
		{name: "voter", type: "checkbox", x:1, y:11, layout:"col-md-12"},
		{name: "reportedFatherName", type: "text", x:1, y:12, layout:"col-md-6"},
		{name: "reportedMotherName", type: "text", x:2, y:12, layout:"col-md-6"},
		{name: "notes", type: "text", x:1, y:13, layout:"col-md-12"}
	];

export const displayReadDeathRecordForm = (id) => {
	let DeathRecordForm = createFormComponent(fields);
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <DeathRecordForm key={uuid} loadData={async () => loadFormData(id)} buttons={() => null}/>
	});
}

const loadFormData = async (id) => {
	return await rest.read('death-record', id).then(response => {
		let form = response;
		let faceUrl = null;
		if (response.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + response.faceId;
		}
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (faceUrl != null)?faceUrl: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['death-record-tag'] = {deathRecordId: id};
		if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('death-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
            showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}