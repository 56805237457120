export const MtlbType = {
	"ID_CARD": 1
	,
	"CIVIL_STATUS_INCLUSION": 2
	,
	"DEATH": 3
	,
	"BIRTH_REGISTRATION": 4
	,
	"USURPATION_CLAIM": 5
	,
	"CIVIL_STATUS_CHANGE": 6
	,
	"ADMIN_INFO_UPDATE": 7
	,
	"DIGITALIZATION": 8
	,
	"MARRIAGE": 9
	,
	"DIVORCE": 10
	,
	"ADOPTION": 11
	,
	"DEATH_REGISTRATION": 12
	,
	"RESIDENCE_CARD": 13
	
}
Object.freeze(MtlbType);
const toTypeMap = {};
Object.keys(MtlbType).forEach(key => {
	toTypeMap[MtlbType[key]] = key;
});
export function getMtlbType (value) {
	return toTypeMap[value];
}

export function getAllMtlbType () {
    return Object.keys(MtlbType);
}

export const MTLB_TYPE_ID_CARD = "ID_CARD";
export const MTLB_TYPE_CIVIL_STATUS_INCLUSION = "CIVIL_STATUS_INCLUSION";
export const MTLB_TYPE_DEATH = "DEATH";
export const MTLB_TYPE_BIRTH_REGISTRATION = "BIRTH_REGISTRATION";
export const MTLB_TYPE_USURPATION_CLAIM = "USURPATION_CLAIM";
export const MTLB_TYPE_CIVIL_STATUS_CHANGE = "CIVIL_STATUS_CHANGE";
export const MTLB_TYPE_ADMIN_INFO_UPDATE = "ADMIN_INFO_UPDATE";
export const MTLB_TYPE_DIGITALIZATION = "DIGITALIZATION";
export const MTLB_TYPE_MARRIAGE = "MARRIAGE";
export const MTLB_TYPE_DIVORCE = "DIVORCE";
export const MTLB_TYPE_ADOPTION = "ADOPTION";
export const MTLB_TYPE_DEATH_REGISTRATION = "DEATH_REGISTRATION";
export const MTLB_TYPE_RESIDENCE_CARD = "RESIDENCE_CARD";
