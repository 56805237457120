import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadCountryForm } from "../../forms/metadata/CountryForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { COUNTRY_ORDER_BY_NAME } from "../../../../auto/js/metadata/CountryOrderBy";

const fields = [
    {title: "id", field: "id"},
    {title: "name", field: "name"},
    {title: "flag", field: "flag", render: rowData => <img src={rowData.flag} onError={(e)=>{e.target.onerror = null; e.target.src="/public/flag.png"}} style={{width: 70, height: 40}}/>}
];

export const displayCountriesList = () => {
    let CountriesList = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <CountriesList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} />
	});
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["country"] = {};
    filter["orderBy"] = COUNTRY_ORDER_BY_NAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='')
        filter["country"]["name"]= query.search;
    return await getCountriesData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
}

const countData = async (filter) => {
    return await rest.count("country", filter);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let flag = null;
        if (row.flag != null) {
			let mimeType = row['flagMimeType'];
			flag = "data:".concat(mimeType, ";base64,", row.flag);		
		}
        row.flag = (flag != null)?flag:"/public/flag.png"; 
      newRows.push(row);
    }
    return newRows;
}

const getCountriesData = async (filter) => {
    return await rest.search("country", filter);
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadCountryForm(rowData.id);
                }
        }
    )
    return actions;   
}