import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { setContextualOptions } from '../../../auto/js/widgets/RibbonTab'; 
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { buildEmptyObject, getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { PHONE_NUMBER_ORDER_BY_PHONE_NUMBER } from "../../../auto/js/metadata/PhoneNumberOrderBy";
import { EMAIL_ORDER_BY_EMAIL } from "../../../auto/js/metadata/EmailOrderBy";
import { ADDRESS_ORDER_BY_PROVINCE_NAME } from "../../../auto/js/metadata/AddressOrderBy";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { showNotification } from "../../../auto/js/utils";
import { addressFields } from "../lists/AddressList";
import { emailFields } from "../lists/EmailList";
import { phoneNumberFields } from "../lists/PhoneNumberList";
import { AttachmentsArea } from "../../../auto/js/widgets";
import { BirthCertificate } from './BirthCertificate';
import * as Yup from 'yup';

import '../../css/printable.css';
import QRCode from "react-qr-code";
import { MTLB_TYPE_ID_CARD } from "../../../auto/js/metadata/MtlbType";

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = { 1: "SINGLE", 2: "MARRIED", 3: "DIVORCED", 4: "WIDOWED" };

const fields = [
	{ name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
	{ name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
	{ name: "id", type: "number", x: 1, y: 3, layout: "col-md-12" },
	{ name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6", 
		"validation": Yup.string().nullable().default(undefined).min(2, 'First name must be at least two characters long').required('First name is required')},
	{ name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
	{ name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
	{ name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, 'Last name must be at least two characters long').required('Last name is required')},
	{name: "fifthname", type: "text", x:1, y: 6, layout:"col-md-12"},
	{ name: "gender", type: "select", x: 1, y: 7, layout: "col-md-6", metadata: () => gender },
	{ name: "maritalStatus", type: "select", x: 2, y: 7, layout: "col-md-6", metadata: () => maritalStatus },
	{ name: "birthdate", type: "date", x: 1, y: 8, layout: "col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')},
	{ name: "birthCountry", type: "autocomplete", x: 2, y: 8, layout: "col-md-6", metadata: () => metadataLoader.get('country') },
	{ name: "birthDayUnknown", type: "checkbox", x: 1, y: 9, layout: "col-md-12" },
	{ name: "birthMonthUnknown", type: "checkbox", x: 1, y: 10, layout: "col-md-12" },
	{ name: "birthYearUnknown", type: "checkbox", x: 1, y: 11, layout: "col-md-12" },
	{ name: "voter", type: "checkbox", x: 1, y: 12, layout: "col-md-12" },
	{ name: "reportedFatherName", type: "text", x: 1, y: 13, layout: "col-md-6" },
	{ name: "reportedMotherName", type: "text", x: 2, y: 13, layout: "col-md-6" },
	{ name: "notes", type: "text", x: 1, y: 14, layout: "col-md-12" }
];

const printBirthCertificate = (data) => () => {
	PRINT_EVENT.publish(<BirthCertificate data={data}/>);
}

const applyIdCard = (id) => () => {
	let dto = pojoMetadata['id-mtlb'].form2dto(buildEmptyObject('id-mtlb'));
	dto.vitalRecordId = id;
	dto.mtlbType = MTLB_TYPE_ID_CARD;
	rest.request(getServiceUri() + 'apply/create-id-mtlb','POST', dto).then((response) => {
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Created Id Card Application`, "success");
	});
}

export const displayReadCivilRecordForm = (id) => {
	let CivilRecordForm = createFormComponent(fields);
	let AddressList = createTableComponent(addressFields);
	let EmailList = createTableComponent(emailFields);
	let PhoneNumberList = createTableComponent(phoneNumberFields);
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid,
		view: () => {
			return (
				<>
					<CivilRecordForm key={uuid} loadData={async () => loadFormData(id)} readOnly buttons={() => null}/>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{t`Address`}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<AddressList key={uuid} loadData={async (query) => buildAddressData(query, id)} />
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{t`Email`}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<EmailList key={uuid} loadData={async (query) => buildEmailData(query, id)} />
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{t`PhoneNumber`}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<PhoneNumberList key={uuid} loadData={async (query) => buildPhoneNumberData(query, id)} />
						</AccordionDetails>
					</Accordion>
					<AttachmentsArea loadData={async () => loadVitalRecordAttachments(id)} handleClick={(aid) => handleVitalRecordAttachmentClick(aid)} />
				</>
			)
		}
	});
}

const loadFormData = async (id) => {
	return await rest.read('vital-record', id).then(response => {
		let form = response;
		let faceUrl = null;
		if (response.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + response.faceId;
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (faceUrl != null) ? faceUrl : '/public/avatar.png', isEmpty: true };
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
		setContextualOptions({
			"civil-records": {
				submenu: {
					"cr-form": {
						options: {
							"birth-certificate": { 
								label: "Birth Certificate", do: printBirthCertificate(form)
							},
							"id-card" : {
								label: "Id Card Application", do: applyIdCard(id)
							}
						}
					}
				}
			}
		});
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

const buildAddressData = async (query, id) => {
	let filter = query;
	let data;
	filter["address"] = { vitalRecordId: id };
	filter["orderBy"] = ADDRESS_ORDER_BY_PROVINCE_NAME;
	filter.orderDirection = null;
	filter.offset = query.page * query.pageSize;
	if (query.search && query.search != '') {
		pojoMetadata["address"].columns.forEach(element => {
			if (element.type == 'text') {
				filter["address"][element.key] = query.search;
			}
		});
		filter["and"] = false;
	}
	return await getData(filter, 'address').then(response => {
		data = filterData(response)
		return countData(filter, 'address').then((count) => { return { data: data, totalCount: count, page: query.page } })
	});
}

const buildEmailData = async (query, id) => {
	let filter = query;
	let data;
	filter["email"] = { vitalRecordId: id };
	filter["orderBy"] = EMAIL_ORDER_BY_EMAIL;
	filter.offset = query.page * query.pageSize;
	filter.orderDirection = null;
	if (query.search && query.search != '') {
		pojoMetadata["email"].columns.forEach(element => {
			if (element.type == 'text') {
				filter["email"][element.key] = query.search;
			}
		});
		filter["and"] = false;
	}
	return await getData(filter, 'email').then(response => {
		data = response;
		return countData(filter, 'email').then((count) => { return { data: data, totalCount: count, page: query.page } })
	});
}

const buildPhoneNumberData = async (query, id) => {
	let filter = query;
	let data;
	filter["phone-number"] = { vitalRecordId: id };
	filter["orderBy"] = PHONE_NUMBER_ORDER_BY_PHONE_NUMBER;
	filter.orderDirection = null;
	filter.offset = query.page * query.pageSize;
	if (query.search && query.search != '') {
		pojoMetadata["phone-number"].columns.forEach(element => {
			if (element.type == 'text') {
				filter["phone-number"][element.key] = query.search;
			}
		});
		filter["and"] = false;
	}
	return await getData(filter, 'phone-number').then(response => {
		data = response;
		return countData(filter, 'phone-number').then((count) => { return { data: data, totalCount: count, page: query.page } })
	});
}

const getData = async (filter, subsection) => {
	return await rest.search(subsection, filter)
}

const countData = async (filter, subsection) => {
	return await rest.count(subsection, filter);
}

const filterData = (DefaultRows) => {
	const newRows = [];
	for (let i in DefaultRows) {
		let row = DefaultRows[i];
		let fromDate = row.fromDate
		if (fromDate !== null) {
			let date = new Date(fromDate[0] + "/" + fromDate[1] + "/" + fromDate[2]);
			date.setTime(date.getTime() + 60 * 60 * 1000)
			row.fromDate = date;
		}
		let toDate = row.toDate
		if (toDate !== null) {
			let date = new Date(toDate[0] + "/" + toDate[1] + "/" + toDate[2]);
			date.setTime(date.getTime() + 60 * 60 * 1000)
			row.toDate = date;
		}
		newRows.push(row);
	}
	return newRows;
}

const handleVitalRecordAttachmentClick = (id) => {
	window.location = getServiceUri() + 'vital-record-attachment' + '/' + id;
};

const loadVitalRecordAttachments = async (id) => {
	let filter = { and: true };
	filter['vital-record-attachment'] = {};
	filter['vital-record-attachment']['vitalRecordId'] = id;
	return rest.search('vital-record-attachment', filter)
}