import { displayApprovedBirthRegistrationList } from '../lists/birthRegistration/ApprovedBirthRegistrationsList'
import { displayNewBirthRegistrationForm } from '../forms/birthRegistration/NewBirthRegistrationForm'
import { displayPendingBirthRegistrationsList } from '../lists/birthRegistration/PendingBirthRegistrationsList'
import { displayReadyBirthRegistrationsList } from '../lists/birthRegistration/ReadyBirthRegistrationsList'
import { displayRejectedBirthRegistrationsList } from '../lists/birthRegistration/RejectedBirthRegistrationList'
import { displayCivilRecordsList } from '../lists/CivilRecordList'
import { displayInactiveCivilRecordsList } from '../lists/InactiveCivilRecordList'
import { displayNewOccupationForm } from '../forms/metadata/OccupationForm'
import { displayNewTranslationsForm } from '../forms/metadata/TranslationsForm'
import { displayNewCountryForm } from '../forms/metadata/CountryForm'
import { displayCountriesList } from '../lists/metadata/CountriesList'
import { displayOccupationList } from '../lists/metadata/OccupationList'
import { displayTranslationsList } from '../lists/metadata/TranslationsList'
import { displayNewDeathRegistrationForm } from '../forms/deathRegistration/NewDeathRegistrationForm'
import { displayApprovedDeathRegistrationList } from '../lists/deathRegistartion/ApprovedDeathRegistartionList'
import { displayPendingDeathRegistrationList } from '../lists/deathRegistartion/PendingDeathRegistartionList'
import { displayRejectedDeathRegistrationList } from '../lists/deathRegistartion/RejectedDeathRegistrationList'
import { displayDeathRecordList } from '../lists/DeathRecordList'
import { whoami } from '../users/UserInfo';

export const menu = () => {
	return {
		...((whoami().roles.includes('ALL') || whoami().roles.includes('CLERK') || whoami().roles.includes('REGISTRAR')) && {
			"civil-records": {
				submenu: {
					"birth-registration": {
						options: {
							new: { label: "New", do: displayNewBirthRegistrationForm },
							approved: { label: "Approved", do: displayApprovedBirthRegistrationList },
							pending: { label: "Pending", do: displayPendingBirthRegistrationsList },
							rejected: { label: "Rejected", do: displayRejectedBirthRegistrationsList },
							...((whoami().roles.includes('REGISTRAR') || whoami().roles.includes('ALL')) && {
								ready: { label: "Ready", do: displayReadyBirthRegistrationsList },
							})
						},
						label: "Birth Registration"
					},
					"books": {
						options: {
							search: { label: "Civil Records", do: displayCivilRecordsList },
							searchInactive: { label: "Inactive Civil Records", do: displayInactiveCivilRecordsList }
						},
						label: "Books"
					}
				},
				label: "Birth"
			},
			"death-records": {
				submenu: {
					"death-registration": {
						options: {
							new: { label: "New", do: displayNewDeathRegistrationForm },
							approved: { label: "Approved", do: displayApprovedDeathRegistrationList },
							pending: { label: "Pending", do: displayPendingDeathRegistrationList },
							rejected: { label: "Rejected", do: displayRejectedDeathRegistrationList }
						},
						label: "Death Registration"
					},
					other: {
						options: {
							search: { label: "Death Records", do: displayDeathRecordList }
						}
					}
				},
				label: "Death"
			},
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
			"crvs-admin": {
				submenu: {
					"occupations": {
						options: {
							list: { label: "Occupations", do: displayOccupationList },
							new: { label: "New", do: displayNewOccupationForm },
						},
						label: "Occupations"
					},
					"countries": {
						options: {
							list: { label: "Countries", do: displayCountriesList },
							new: { label: "New", do: displayNewCountryForm },
						},
						label: "Countries"
					},
					"translations": {
						options: {
							list: { label: "Translations", do: displayTranslationsList },
							new: { label: "New", do: displayNewTranslationsForm },
						},
						label: "Translations"
					},
				},
				label: "Configuration"
			}
		}),
	}
}
