import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadTranslationsForm } from "../../forms/metadata/TranslationsForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { I18N_ORDER_BY_MESSAGE_ID } from "../../../../auto/js/metadata/I18nOrderBy";

const fields = [
    {title: "messageId", field: "messageId"},
    {title: "value", field: "value"},
    {title: "localeId", field: "localeId"},
];

export const displayTranslationsList = () => {
    let TranslationsList = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <TranslationsList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} />
	});
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["i18n"] = {};
    filter["orderBy"] = I18N_ORDER_BY_MESSAGE_ID ;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        fields.forEach(element => {
            filter["i18n"][element.field] = query.search;
        });
        filter["and"] = false;
    }        
    return await getTranslationsData(filter).then(response => {
        data = response;
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
}

const countData = async (filter) => {
    return await rest.count("i18n", filter);
}

const getTranslationsData = async (filter) => {
    return await rest.search("i18n", filter);
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadTranslationsForm(rowData.id);
                }
        }
    )
    return actions;   
}