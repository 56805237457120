import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";

import { birthRegistrationFields, updateRejectedBirthRegistrationForm, loadBirthRegistrationFormData } from "../birthRegistration/BirthRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";

import { createTableComponent } from "../../widgets/TableComponent";
import { addressFields, buildAddressData, getAddressEditables } from "../../lists/AddressList";
import { emailFields, buildEmailData, getEmailEditables } from "../../lists/EmailList";
import { phoneNumberFields, buildPhoneNumberData, getPhoneNumberEditables } from "../../lists/PhoneNumberList";
import { loadCivilStatusMtlbAttachment } from "../birthRegistration/BirthRegistrationFormCommon";
		
let BirthRegistrationForm = createFormComponent(birthRegistrationFields);
let AddressList = createTableComponent(addressFields);
let EmailList = createTableComponent(emailFields);
let PhoneNumberList = createTableComponent(phoneNumberFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<BirthRegistrationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} onSubmit={update} id={this.props.id} buttons={() => getButtons(this.props.id)} />
			<Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`Address`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AddressList key={this.props.key} loadData={async (query) => buildAddressData(query, this.props.id)}  editable={getAddressEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
			<Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`Email`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EmailList key={this.props.key} loadData={async (query) => buildEmailData(query, this.props.id)}  editable={getEmailEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
			<Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`PhoneNumber`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PhoneNumberList key={this.props.key} loadData={async (query) => buildPhoneNumberData(query, this.props.id)}  editable={getPhoneNumberEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
            <FileUploader onDropCallback={(file) => uploadCivilStatusMtlbAttachment(file, this.props.id)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleDelete={(id) => handleCivilStatusMtlbAttachmentDelete(id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const uploadCivilStatusMtlbAttachment = (file, id) => {
	rest.upload('civil-status-mtlb-attachment', id, file).then((response) => FILE_UPLOADED_EVENT.publish({ id: response.id, fileName: file.name }))
}
const handleCivilStatusMtlbAttachmentDelete = (id) => {
	rest.delete('civil-status-mtlb-attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const handleCivilStatusMtlbAttachmentClick = (id) => {
	window.location = getServiceUri() + 'civil-status-mtlb-attachment' + '/' + id;
};

export const displayReadRejectedBirthRegistrationForm = (id) => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} id={id}/>
	});
}

const update = async (formData) => {
	return updateRejectedBirthRegistrationForm(formData);
}

const loadFormData = async (id) => {
	return await loadBirthRegistrationFormData(id);
}

const getButtons = (id) => {
	return (
    <>
        <button style={{ minWidth: '5rem' }} type="submit"> {t`Reopen`} </button>
    </>
    )
}