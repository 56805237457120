import { rest } from "../services";

class GeoMetadataLoader {
    
    constructor() {
        if (!!GeoMetadataLoader.instance) {
            return GeoMetadataLoader.instance;
        }
        GeoMetadataLoader.instance = this;
        this.metadata = {};
        return this;
    }    
    
	load = async () => {
		let filter = {};
        filter['locations'] = {};
       	return rest.search('locations', filter)
            .then(response => {
                response.forEach(element => {
                    if  (!this.metadata[element['provinceName']])
                        this.metadata[element['provinceName']] = {};
                    if  (!this.metadata[element['provinceName']][element['zoneName']])
                        this.metadata[element['provinceName']][element['zoneName']] = {}
                    if  (!this.metadata[element['provinceName']][element['zoneName']][element['districtName']])
                        this.metadata[element['provinceName']][element['zoneName']][element['districtName']] = {}
                    if  (!this.metadata[element['provinceName']][element['zoneName']][element['districtName']][element['islandName']])
                        this.metadata[element['provinceName']][element['zoneName']][element['districtName']][element['islandName']] = {}
                    if  (!this.metadata[element['provinceName']][element['zoneName']][element['districtName']][element['islandName']][element.locationName])
                        this.metadata[element['provinceName']][element['zoneName']][element['districtName']][element['islandName']][element.locationName] = element.locationName 
             });
        })
    }
    
    getProvinces = () => {
    	return this.metadata;
    }

}

export const geoMetadataLoader = new GeoMetadataLoader();
