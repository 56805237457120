import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { t } from "../services";
import { AttachmentsArea } from "./Attachments";

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const focusedStyle = {
	borderColor: '#2196f3'
  };
  
  const acceptStyle = {
	borderColor: '#00e676'
  };
  
  const rejectStyle = {
	borderColor: '#ff1744'
  };

export const FileUploader = (props) => {
	const onDrop = (acceptedFiles) => props.onDropCallback(acceptedFiles[0]);
	const {
		getRootProps,
      	getInputProps,
		isDragActive, 
		isFocused,
		isDragAccept,
		isDragReject
	  } = useDropzone({ onDrop });

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	  }), [
		isDragActive,
		isFocused,
		isDragAccept,
		isDragReject
	  ]);

    return (
		<>
        	<AttachmentsArea {...props}/>
			<div {...getRootProps({style})}>
				<input {...getInputProps()} />
				{
					isDragActive ?
						<p>{t`Drop the files here ...`}</p> :
						<p>{t`Drag and drop some files here, or click to select files`}</p>
				}
        	</div>
			
		</>
	)
}