import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MtlbStatus, MTLB_STATUS_REJECTED } from "../../../../auto/js/metadata/MtlbStatus";
import {  MtlbType, MTLB_TYPE_DEATH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { displayReadRejectedDeathRegistrationForm } from "../../../../auto/js/forms/deathRegistration/RejectedDeathRegistrationForm";
import { CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/CivilStatusMtlbOrderBy";

const fields = [
    {title:"image", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "birthdate", field: "birthdate"},
];

export const displayRejectedDeathRegistrationList = () => {
    let RejectedDeathRegistrationTable = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <RejectedDeathRegistrationTable key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} />
	});
}


const buildData = async (query) => {
    let filter = query;
    let data;
    filter["civil-status-mtlb"] = {mtlbStatusList: [MTLB_STATUS_REJECTED], mtlbTypeList: [MTLB_TYPE_DEATH_REGISTRATION]};
    filter['and'] = true;
    filter["orderBy"] = CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["civil-status-mtlb"].columns.forEach(element => {
            if(element.type=='text' ){
                filter["civil-status-mtlb"][element.key]= query.search;
            }
        });
    }
    return await getDeathRegistrationData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getDeathRegistrationData = async (filter) => {
    return await rest.request(getServiceUri() + "civil-status-mtlb/search-by-mtlb-type-and-status/", "POST", filter);
}

const countData = async (filter) => {
    return await rest.request(getServiceUri() + "civil-status-mtlb/count/search-by-mtlb-type-and-status/", "POST", filter["civil-status-mtlb"]);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let face = null;
        if (row.face != null) {
			let mimeType = row['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", row.face);		
		}
        row.image = (face != null)?face:"/public/avatar.png";
        let date = row.birthdate
        if ( date !== null)
        row.birthdate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadRejectedDeathRegistrationForm(rowData.id);
                }
        }
    )
    return actions;   
}