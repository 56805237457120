import React from "react";

import { buildEmptyObject } from "../../widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../metadata";
import { MTLB_TYPE_DEATH_REGISTRATION } from "../../metadata/MtlbType";
import { rest, t } from "../../services";
import { showNotification } from "../../utils";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};

export const deathRegistrationFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name:"image", type:"image", x:1, y:2, layout:"col-md-12"},
    {name: "firstname", type: "text", x:1, y:3, layout:"col-md-6"},
    {name: "secondname", type: "text", x:2, y:3, layout:"col-md-6"},
    {name: "thirdname", type: "text", x:1, y: 4, layout:"col-md-6"},
    {name: "fourthname", type: "text", x:2, y: 4, layout:"col-md-6"},
    {name: "fifthname", type: "text", x:1, y: 5, layout:"col-md-12"},
    {name: "gender", type: "select", x:1, y: 6, layout:"col-md-6", metadata: () => gender},
    {name: "maritalStatus", type: "select", x:2, y:6, layout:"col-md-6", metadata: () => maritalStatus},
    {name: "birthdate", type: "date", x:1, y: 7, layout:"col-md-6"},
    {name: "dateOfDeath", type: "date", x:1, y: 7, layout:"col-md-6"},	
    {name: "birthDayUnknown", type: "checkbox", x:1, y:8, layout:"col-md-12"},
    {name: "birthMonthUnknown", type: "checkbox", x:1, y:9, layout:"col-md-12"},
    {name: "birthYearUnknown", type: "checkbox", x:1, y:10, layout:"col-md-12"},
    {name: "reportedFatherName", type: "text", x:1, y:11, layout:"col-md-6"},
    {name: "reportedMotherName", type: "text", x:2, y:11, layout:"col-md-6"},
    {name: "notes", type: "text", x:1, y:12, layout:"col-md-12"}
];

export const saveDeathRegistrationForm = async (formData) => {
    let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
    dto.mtlbType = MTLB_TYPE_DEATH_REGISTRATION;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadDeathRegistrationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}	
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
            if (tags.status)
                showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Death Registration`, "success");
		});;
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Reopened Death Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectDeathRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Rejected Death Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const buildDeathRegistrationFormEmptyObject  = () => {
    const empty = buildEmptyObject(deathRegistrationFields);
    empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	return empty;
}

export const updateDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	dto.draft = false;
    try {
        return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Death Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteDeathRegistrationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id);
    } catch (err) {
        alert(err);
    }
}