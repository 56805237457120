export const ADDRESS_ORDER_BY_CIVIL_STATUS_MTLB_ID = "CIVIL_STATUS_MTLB_ID";
export const ADDRESS_ORDER_BY_DEATH_RECORD_ID = "DEATH_RECORD_ID";
export const ADDRESS_ORDER_BY_DISTRICT_NAME = "DISTRICT_NAME";
export const ADDRESS_ORDER_BY_FROM_DATE = "FROM_DATE";
export const ADDRESS_ORDER_BY_ID = "ID";
export const ADDRESS_ORDER_BY_ISLAND_NAME = "ISLAND_NAME";
export const ADDRESS_ORDER_BY_LOCATION_NAME = "LOCATION_NAME";
export const ADDRESS_ORDER_BY_POLLING_STATION_NAME = "POLLING_STATION_NAME";
export const ADDRESS_ORDER_BY_PROVINCE_NAME = "PROVINCE_NAME";
export const ADDRESS_ORDER_BY_TO_DATE = "TO_DATE";
export const ADDRESS_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const ADDRESS_ORDER_BY_ZONE_NAME = "ZONE_NAME";
export const ADDRESS_ORDER_BY_ADDRESS_TYPE = "ADDRESS_TYPE";
export const ADDRESS_ORDER_BY_LOCATION_CODE = "LOCATION_CODE";
