export const DEATH_RECORD_ORDER_BY_ADDRESS = "ADDRESS";
export const DEATH_RECORD_ORDER_BY_BIRTH_COUNTRY = "BIRTH_COUNTRY";
export const DEATH_RECORD_ORDER_BY_BIRTH_DAY_UNKNOWN = "BIRTH_DAY_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_BIRTH_MONTH_UNKNOWN = "BIRTH_MONTH_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_BIRTH_PLACE = "BIRTH_PLACE";
export const DEATH_RECORD_ORDER_BY_BIRTH_TIME = "BIRTH_TIME";
export const DEATH_RECORD_ORDER_BY_BIRTH_TIME_UNKNOWN = "BIRTH_TIME_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_BIRTH_YEAR_UNKNOWN = "BIRTH_YEAR_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_BIRTHDATE = "BIRTHDATE";
export const DEATH_RECORD_ORDER_BY_CARD_ID = "CARD_ID";
export const DEATH_RECORD_ORDER_BY_COMMON_ID_NUMBER = "COMMON_ID_NUMBER";
export const DEATH_RECORD_ORDER_BY_DATE_OF_DEATH = "DATE_OF_DEATH";
export const DEATH_RECORD_ORDER_BY_DEATH_DAY_UNKNOWN = "DEATH_DAY_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_DEATH_MONTH_UNKNOWN = "DEATH_MONTH_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_DEATH_TIME_UNKNOWN = "DEATH_TIME_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_DEATH_YEAR_UNKNOWN = "DEATH_YEAR_UNKNOWN";
export const DEATH_RECORD_ORDER_BY_DISABILITY = "DISABILITY";
export const DEATH_RECORD_ORDER_BY_DONOR = "DONOR";
export const DEATH_RECORD_ORDER_BY_DONOR_NOTES = "DONOR_NOTES";
export const DEATH_RECORD_ORDER_BY_DRAFT = "DRAFT";
export const DEATH_RECORD_ORDER_BY_FACE_ID = "FACE_ID";
export const DEATH_RECORD_ORDER_BY_FATHER_ID = "FATHER_ID";
export const DEATH_RECORD_ORDER_BY_FIFTHNAME = "FIFTHNAME";
export const DEATH_RECORD_ORDER_BY_FIRSTNAME = "FIRSTNAME";
export const DEATH_RECORD_ORDER_BY_FOURTHNAME = "FOURTHNAME";
export const DEATH_RECORD_ORDER_BY_GENDER = "GENDER";
export const DEATH_RECORD_ORDER_BY_ID = "ID";
export const DEATH_RECORD_ORDER_BY_IS_DELETED = "IS_DELETED";
export const DEATH_RECORD_ORDER_BY_MARITAL_STATUS = "MARITAL_STATUS";
export const DEATH_RECORD_ORDER_BY_MOTHER_ID = "MOTHER_ID";
export const DEATH_RECORD_ORDER_BY_NOTES = "NOTES";
export const DEATH_RECORD_ORDER_BY_OTHER_CITIZENSHIP_CSV = "OTHER_CITIZENSHIP_CSV";
export const DEATH_RECORD_ORDER_BY_PRIMARY_CITIZENSHIP = "PRIMARY_CITIZENSHIP";
export const DEATH_RECORD_ORDER_BY_REPORTED_FATHER_NAME = "REPORTED_FATHER_NAME";
export const DEATH_RECORD_ORDER_BY_REPORTED_MOTHER_NAME = "REPORTED_MOTHER_NAME";
export const DEATH_RECORD_ORDER_BY_SECONDNAME = "SECONDNAME";
export const DEATH_RECORD_ORDER_BY_THIRDNAME = "THIRDNAME";
export const DEATH_RECORD_ORDER_BY_TIME_OF_DEATH = "TIME_OF_DEATH";
export const DEATH_RECORD_ORDER_BY_TOUCHED = "TOUCHED";
export const DEATH_RECORD_ORDER_BY_VOTER = "VOTER";
