import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { displayReadCivilRecordForm } from "../forms/CivilRecordForm";
import { VITAL_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/VitalRecordOrderBy";

const fields = [
    {title:"image", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "id", field: "id"},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "birthdate", field: "birthdate"},
];

export const displayInactiveCivilRecordsList = () => {
    let CivilRecordsList = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <CivilRecordsList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} />
	});
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["vital-record"] = {inactiveList: [true]};
    filter["orderBy"] =  VITAL_RECORD_ORDER_BY_FIRSTNAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["vital-record"].columns.forEach(element => {
            if(element.type=='text'){
                filter["vital-record"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getCivilRecordData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getCivilRecordData = async (filter) => {
    return await rest.request(getServiceUri() + "vital-record/search-by-active/", "POST", filter);
}

const countData = async (filter) => {
    return await rest.request(getServiceUri() + "vital-record/count/search-by-active/", "POST", filter['vital-record']);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let faceUrl = null;
        if (row.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + row.faceId;	
		}
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";       
        let date = row.birthdate
        if ( date !== null)
        row.birthdate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`View`,
                onClick: (event, rowData) => {
                    displayReadCivilRecordForm(rowData.id);
                }
        }
    )
    return actions;
}