import * as React from 'react';
import BorderWrapper from 'react-border-wrapper';
import QRCode from "react-qr-code";

const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

export class BirthCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	render() {
		let birthdate = this.data.birthdate? formatDate(this.data.birthdate):'Unknown';
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " @ "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();
		let fullname = '';
		fullname += this.data.firstname ? this.data.firstname + ' ' : '';
		fullname += this.data.secondname ? this.data.secondname + ' ' : '';
		fullname += this.data.fourthname ? this.data.fourthname + ' ' : '';
		fullname += this.data.birthdate ? birthdate : '';
		return (
			<BorderWrapper
				borderColour="#00bcf1"
				borderWidth="5px"
				borderRadius="15px"
				borderType="solid"
				innerPadding="30px"
				topPosition={0.05}
				topOffset="22px"
				topGap="4px"
				rightPosition={0.1}
				rightOffset="22px"
				rightGap="4px"
			>
				<table>
					<tbody>
						<tr>
							<td><center><img src={this.data.image.url} width="250px"/></center></td>
						</tr>
						<tr>
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td><b>First name:</b> {this.data.firstname} </td>
						</tr>
						<tr>
							<td><b>Second name:</b> {this.data.secondname} </td>
						</tr>
						<tr>
							<td><b>Last name:</b> {this.data.fourthname} </td>
						</tr>
						<tr>
							<td><b>Date of Birth:</b> {birthdate}</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td><center>
								<QRCode value={fullname + "\nCertificate issued on " + datetime + ' - Signed by Director Andy Calo'} />
								</center>
							</td>
						</tr>
					</tbody>
				</table>
			</BorderWrapper>
		)
	}
}