import * as React from "react";
import { useField } from "formik";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export function CheckboxComponent(props) {
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
	const { setValue } = helpers;
	const handleChange = event => {
		setValue(event.target.checked);
	  };
  
	return (
		<FormControlLabel
		control={<Checkbox
		  checked={value}
		  onChange={handleChange}
		  color="primary"
		  value={value}
		  disabled={props.disabled}
		/>}
		label={props.label}
		style={{left: (props.language=="ar")?'24.5rem':'11.5rem', marginTop:'10px'}}
		/>
	);
  }
