import React, { useState } from "react";

import { ADDRESS_ORDER_BY_PROVINCE_NAME } from "../../../auto/js/metadata/AddressOrderBy";
import { pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { geoMetadataLoader } from "../../../auto/js/metadata/GeoMetadataLoader";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import { Observable } from "../../../auto/js/events/Observable";
import { formState } from "../../../auto/js/forms/FormState";

let provinceObservable = new Observable();
let zoneObservable = new Observable();
let districtObservable = new Observable();
let islandObservable = new Observable();
export const addressFields = [
    {title: "provinceName", field: "provinceName",
        editComponent: props => {
            let [options, setOptions] = useState(undefined);
            let [value, setValue] = useState(props.value);
            if (!options) {
                setOptions(geoMetadataLoader.getProvinces())
                return
            }
            return (
                <Autocomplete
                    id={'provinceName'}
                    onChange={(e, value) => {
                        props.onChange(value);
                        setValue(value);
                        provinceObservable.publish(options[value])}
                    }
                    value={value}
                    options={Object.keys(options)} 
                    renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                    )}
                />)
        } 
    },
    {title: "zoneName", field: "zoneName",
        editComponent: props => {
            let [options, setOptions] = useState([]);
            let [value, setValue] = useState(props.value);
            let callback = (event) => {
                if (event)
                    setOptions(event);
                else
                    setOptions([])
                setValue('');
                zoneObservable.publish(undefined);
            }
            provinceObservable.subscribe(callback);
            return (
                <Autocomplete
                    id={'zoneName'}
                    onChange={(e, value) => {
                        props.onChange(value)
                        setValue(value);
                        zoneObservable.publish(options[value]);
                    }}
                    value={value}
                    options={Object.keys(options)}
                    renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                    )}
                />)
        } 
    },
    {title: "districtName", field: "districtName",
        editComponent: props => {
            let [options, setOptions] = useState([]);
            let [value, setValue] = useState(props.value);
            let callback = (event) => {
                if (event)
                    setOptions(event);
                else
                    setOptions([])
                setValue('');
                districtObservable.publish(undefined);
            }
            zoneObservable.subscribe(callback);
            return (
                <Autocomplete
                    id={'districtName'}
                    onChange={(e, value) => {
                        props.onChange(value)
                        setValue(value);
                        districtObservable.publish(options[value]);
                    }}
                    value={value}
                    options={Object.keys(options)} 
                    renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                    )}
                />)
        }
    },
    {title: "islandName", field: "islandName",
        editComponent: props => {
            let [options, setOptions] = useState([]);
            let [value, setValue] = useState(props.value);
            let callback = (event) => {
                if (event)
                    setOptions(event);
                else
                    setOptions([])
                setValue('');
                islandObservable.publish(undefined);
            }
            districtObservable.subscribe(callback);
            return (
                <Autocomplete
                    id={'islandName'}
                    onChange={(e, value) => {
                        props.onChange(value)
                        setValue(value);
                        islandObservable.publish(options[value]);
                    }}
                    value={value}
                    options={Object.keys(options)} 
                    renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                    )}
                />)
        }
    },
    {title: "locationName", field: "locationName",
        editComponent: props => {
            let [options, setOptions] = useState([]);
            let [value, setValue] = useState(props.value);
            let callback = (event) => {
                if (event)
                    setOptions(event);
                else
                    setOptions([])
                setValue('');
            }
            islandObservable.subscribe(callback);
            return (
                <Autocomplete
                    id={'locationName'}
                    onChange={(e, value) => {
                        props.onChange(value)
                        setValue(value);
                    }}
                    value={value}
                    options={Object.keys(options)} 
                    renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                    )}
                />)
        }
    },
    {title: "fromDate", field: "fromDate", type:"date"},
    {title: "toDate", field: "toDate", type:"date"},
]

export const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {civilStatusMtlbId: id};
    filter["orderBy"] = ADDRESS_ORDER_BY_PROVINCE_NAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getData(filter).then(response => {
        data = filterData(response)
        formState.setAddressList(data)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getData = async (filter) => {
    return await rest.search('address', filter)
}

const countData = async (filter) => {
        return await rest.count('address', filter);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];     
        let fromDate = row.fromDate
        if ( fromDate !== null) {
            let date = new Date(fromDate[0]+ "/" +fromDate[1] + "/" + fromDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.fromDate = date;
        }
        let toDate = row.toDate
        if ( toDate !== null) {
            let date = new Date(toDate[0]+ "/" +toDate[1] + "/" + toDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.toDate = date;
        }
      newRows.push(row);
    }
    return newRows;
}

export const getAddressEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['address'].form2dto(newData);
            dto["civilStatusMtlbId"] = id;       
            try {
                return rest.create('address', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }        
        }),
    editables.onRowUpdate = (newData, oldData) =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['address'].form2dto(newData);
            try {
                return rest.update('address', dto).then(() => {
                    formState.addToAddressList(newData)
                    resolve()})
            } catch (err) {
                alert(err);
            }
        }),
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                return rest.purge('address', oldData.id).then(() => resolve());
            } catch (err) {
                alert(err);
            }   
        })
    return editables;
}