import Noty from "noty";
import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

export const showNotification = (text, type) => {
	new Noty({
		text,
		type: type,
		theme: 'relax',
		timeout: 3000,
	}).show();
}

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export let ddmap = ( k, v ) => ( { key: k, value: v } );

export let json2SortedReactSelectArray = ( data ) => {
	const items = [];
   	Object.keys( data ).sort( function( a, b ) {
		return data[a].localeCompare( data[b] ) 
		}).map( k => {
			items.push(ddmap( k, data[k] ))
	});
	return items
}