import { metadataLoader } from "../../auto/js/metadata"
import { geoMetadataLoader } from "../../auto/js/metadata/GeoMetadataLoader"

export const loadCountries = async () => {
    return metadataLoader.load("country")
}

export const loadProvinces = async () => {
    return metadataLoader.load("province")
}

export const loadPollingStations = async () => {
    return metadataLoader.load("polling-stations")
}

export const loadLocations = async () => {
    return geoMetadataLoader.load();
}